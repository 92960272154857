/* eslint-disable @typescript-eslint/no-unused-vars */
export const environment = {
  production: false,
  protocol: 'https',
  api_host: "mock.apidog.com/m1/499082-0-default",
  getReservationUrl: "https://pms-myprivacy-be-uploads-staging.s3.eu-central-1.amazonaws.com",
  postGuestUrl: "https://ovjm4qt8pk.execute-api.eu-central-1.amazonaws.com/staging",
  POSTHOG_KEY: "",
  POSTHOG_HOST: "",
  WEB_ROOT: "102",
};
